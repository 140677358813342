import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/Layout.vue';

Vue.use(VueRouter);

const routes = [
    // 登录页
    { path: '/login', component: () => import('@/views/index/Login.vue') },
    // 注册页
    { path: '/register', component: () => import('@/views/index/Register.vue') },
    // 忘记密码
    { path: '/forget', component: () => import('@/views/index/Forget.vue') },
    // 登陆页的用户协议
    {
        path: '/agreement',
        component: () => import('@/views/index/Agreement.vue')
    },
    // 注册页的服务条款
    { path: '/terms', component: () => import('@/views/index/Terms.vue') },
    // 状态栏四个导航
    {
        path: '/',
        component: Layout,
        redirect: '/index',
        children: [
            {
                path: '/index',
                component: () => import('@/views/index/Index.vue')
            },
            {
                path: '/news',
                component: () => import('@/views/news/Index.vue')
            },
            {
                path: '/trade_:id',
                component: () => import('@/views/trade/Index.vue')
            },
            {
                path: '/trade',
                component: () => import('@/views/trade/Index.vue')
            },
            {
                path: '/assets',
                component: () => import('@/views/assets/Index.vue')
            }
        ]
    },

    // 使用教程
    {
        path: '/course',
        component: () => import('@/views/course/Index.vue')
    },
    {
        path: '/course/detail/:id',
        component: () => import('@/views/course/Detail.vue')
    },
    // person 设置页
    {
        path: '/person/setting',
        component: () => import('@/views/person/Setting')
    },
    // paeson 修改密码
    {
        path: '/person/setting/changepassword',
        component: () => import('@/views/person/Changepassword.vue')
    },
    // person 联系方式
    {
        path: '/person/setting/bank',
        component: () => import('@/views/person/Bank.vue')
    },
    // person 认证页
    {
        path: '/person/authentication',
        component: () => import('@/views/person/Authentication')
    },
    // person 身份认证
    {
        path: '/person/identity',
        component: () => import('@/views/person/Identity')
    },
    // person 高级认证
    {
        path: '/person/advanced',
        component: () => import('@/views/person/Advanced')
    },
    // person 关于我们
    {
        path: '/person/adoutus',
        component: () => import('@/views/person/Adoutus')
    },
    // person 关于我们 - 隐私策略
    {
        path: '/person/adoutus-privacy-policy',
        component: () => import('@/views/person/Adoutus-privacy-policy')
    },
    // person 关于我们 - 条款与服务
    {
        path: '/person/adoutus-terms-service',
        component: () => import('@/views/person/Adoutus-terms-service')
    },
    // person 关于我们 - 操作守则
    {
        path: '/person/adoutus-codeconduct-users',
        component: () => import('@/views/person/Adoutus-codeconduct-users')
    },
    // person 关于我们 - 免责声明
    {
        path: '/person/adoutus-disclaimer',
        component: () => import('@/views/person/Adoutus-disclaimer')
    },
    // person 关于我们 - 服务协议
    {
        path: '/person/adoutus-service-agreement',
        component: () => import('@/views/person/Adoutus-service-agreement')
    },
    // 理财首页
    {
        path: '/fund',
        component: () => import('@/views/fund/Index.vue')
    },
    // 理财 产品介绍
    {
        path: '/fund/introduction',
        component: () => import('@/views/fund/Introduction.vue')
    },
    // 理财 产品详情
    {
        path: '/fund/introduction/detail/:id',
        component: () => import('@/views/fund/IntroductionDetail.vue')
    },
    // 理财 投资发布
    {
        path: '/fund/investment',
        component: () => import('@/views/fund/Investment.vue')
    },
    // 理财 投资发布详情页
    {
        path: '/fund/investment/detail/:id',
        component: () => import('@/views/fund/InvestmentDetail.vue')
    },
    // 理财 投资标池
    {
        path: '/fund/pool',
        component: () => import('@/views/fund/Pool.vue')
    },
    // 理财 投资标池详情
    {
        path: '/fund/pool/detail/:id',
        component: () => import('@/views/fund/PoolDetail.vue')
    },
    // 新闻详情页
    {
        path: '/news/detail/:id',
        component: () => import('@/views/news/Detail.vue')
    },
    // 资产 充币页
    {
        path: '/assets/recharge',
        component: () => import('@/views/assets/Recharge.vue')
    },
    // 资产 提币页
    {
        path: '/assets/withdraw',
        component: () => import('@/views/assets/Withdraw.vue')
    },
    // 资产 划转页
    {
        path: '/assets/transfer',
        component: () => import('@/views/assets/Transfer.vue')
    },
    // 资产 兑换页
    {
        path: '/assets/conversion',
        component: () => import('@/views/assets/Conversion.vue')
    },
    // 法币交易页
    {
        path: '/trade/shopping',
        component: () => import('@/views/trade/Shopping.vue')
    },
    // 法币外汇页
    {
        path: '/trade/Foreign',
        component: () => import('@/views/trade/Foreign.vue')
    },
    // 我要卖页
    {
        path: '/trade/shopping/sell',
        component: () => import('@/views/trade/Sell.vue')
    },
    // 交易记录
    {
        path: '/trade/shopping/trading',
        component: () => import('@/views/trade/Trading.vue')
    },
    // 购买交易详情
    {
        path: '/trade/shopping/trading/buy_detail/:id',
        component: () => import('@/views/trade/Buy_detail.vue')
    },
    // 购买付款页面
    {
        path: '/trade/shopping/trading/buy_pay/:id',
        component: () => import('@/views/trade/Buy_pay.vue')
    },
    // 联系卖家页面
    {
        path: '/trade/shopping/trading/contact/:id',
        component: () => import('@/views/trade/Contact.vue')
    },
    // 确认出售详情
    {
        path: '/trade/shopping/trading/sell_detail/:id',
        component: () => import('@/views/trade/Sell_detail.vue')
    },
    // 联系列表页
    {
        path: '/trade/contact/list',
        component: () => import('@/views/trade/Contact_list.vue')
    },
];

const router = new VueRouter({
    routes
});
// 路由守卫
router.beforeEach((to, from, next) => {
    // 如果访问登陆页 直接放行
    if (
        to.path === '/login' ||
        to.path === '/register' ||
        to.path === '/forget' ||
        to.path === '/agreement' ||
        to.path === '/terms'
    ) {
        return next();
    }
    // 获取token
    const tokenstr = window.localStorage.getItem('token');
    if (tokenstr) {
        next();
    } else {
        next(`/login?redirect=${to.path}`);
    }
});

export default router;
